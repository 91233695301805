export const getSelectedTenant = async (breadcrumbs) => {
  const selectedTenantId = await breadcrumbs.getBreadcrumbs()
  return selectedTenantId
}
export const isServiceProvider = async (criterion) => {
  const criterionCheck = await criterion.check({
    type: 'scope',
    value: 'ws-hp.com/ecp/serviceProvider.READ',
    options: {
      userContext: 'organization'
    }
  })
  return criterionCheck
}
export const isServiceCustomer = async (criterion) => {
  const criterionCheck = await criterion.check({
    type: 'scope',
    value: 'ws-hp.com/ecp/serviceCustomer.READ',
    options: {
      userContext: 'organization'
    }
  })
  return criterionCheck
}
