import styled from 'styled-components'
// You can use variables from veneer tokens and do something like the below
import primitives from '@veneer/primitives'

export const Container = styled.div`
  font-family: ${primitives.typography.family0};
  line-height: ${primitives.typography.lineHeight2};
  .orgStyle {
    display: flex;
    font-family: ${primitives.typography.family0};
    align-items: flex-start;
    justify-content: space-between;
    padding: ${primitives.layout.size6}px;
    position: relative;
    line-height: ${primitives.typography.lineHeight6};
    background: linear-gradient(
      99.09deg,
      #013650 0%,
      #013752 2.5%,
      #027aae 115.59%
    );
  }
  .tenantStyle {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-family: ${primitives.typography.family0};
    padding: ${primitives.layout.size6}px;
    position: relative;
    line-height: ${primitives.typography.lineHeight6};
    background: linear-gradient(
      96.56deg,
      #0c9f98 0.01%,
      #0c9f98 0.02%,
      #0b9a96 43.03%,
      #035c84 91.31%
    );
  }
`
export const HeaderTitleText = styled.div`
  font-size: ${primitives.typography.size6};
  color: #ffffff;
`
export const ReloadText = styled.div`
  font-size: ${primitives.typography.size1};
  color: #ebebeb;
  button {
    color: #3ab4eb;
    :hover {
      color: #0278ab;
    }
  }
  .caption {
    font-family: ${primitives.typography.family0};
    @media (max-width: 576px) {
      display: none;
    }
  }
`
export const DetailsStyle = styled.span`
  cursor: pointer;
`
