import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Container, HeaderTitleText, ReloadText, DetailsStyle } from './styles'
import {
  isServiceProvider,
  isServiceCustomer,
  getSelectedTenant
} from '../../utils/api'
import { IconReload, Button } from '@veneer/core'
import { dateFormat } from '../../utils/constants'
import { getCurrentDateTime } from '../../utils/commonMethods'
import useShellRootContext from '../../contexts/ShellRoot/useShellRootContext'
import { AppPropsType } from 'src/App/types'

const EcpHeader = (props: AppPropsType) => {
  const [isDetailsPage, setIsDetailsPage] = useState(false)
  const [isTenantRoute, setIsTenantRoute] = useState<boolean>(
    props.mockProp ? true : false
  )
  const [serviceProvider, setServiceProvider] = useState<boolean>(
    props.mockProp ? true : false
  )
  const [serviceCustomer, setServiceCustomer] = useState<boolean>(
    props.mockProp ? true : false
  )
  const [emptyTitle, setEmptyTitle] = useState<boolean>(
    props.mockProp ? true : false
  )
  const [tenantName, setTenantName] = useState<string>('')
  const [headerTitle, setHeaderTitle] = useState<string>('')
  const [lastSynced, setLastSynced] = useState(
    getCurrentDateTime(dateFormat.default)
  )

  const { t = (key, defaultValue) => defaultValue || key, interfaces } =
    useShellRootContext?.() || {}
  const events = interfaces?.v1?.events || props.mockEvents
  const criterion = interfaces?.v1?.criterion || props.mockCriterion
  const breadcrumbs = interfaces?.v1?.breadcrumbs || props.mockBreadcrumbs
  const navigation = interfaces?.v1?.navigation || props.mockNavigation
  const handleReloadPage = useCallback(() => {
    setLastSynced(getCurrentDateTime(dateFormat.default))
    events.triggerEvent('ecp-banner-reload-call', {})
  }, [events])

  const retriveSelectedTenant = useCallback(async () => {
    try {
      const response = await getSelectedTenant(breadcrumbs)
      const isHome = response[0]?.key === 'home'
      setIsTenantRoute(isHome)
      setTenantName(isHome ? '' : response[0]?.text)
      setHeaderTitle(
        props.title || t(`ecp-header.${response[1]?.key}`, response[1]?.text)
      )
      const isDetailsText =
        response[response.length - 1]?.text.toLowerCase() ===
        t('ecp-header.details', 'details')
      setEmptyTitle(isDetailsText)
      setIsDetailsPage(isDetailsText)
    } catch (e) {
      console.log(e)
    }
  }, [breadcrumbs, props.title, t])

  const detailsNavigation = useCallback(() => {
    if (isDetailsPage && headerTitle) {
      return (
        <DetailsStyle
          data-testid="details-navigation"
          onClick={() => navigation.goBack()}
        >
          {headerTitle}
        </DetailsStyle>
      )
    }
  }, [headerTitle, isDetailsPage, navigation])

  const getServiceProvider = useCallback(async () => {
    try {
      const response = await isServiceProvider(criterion)
      setServiceProvider(response)
    } catch (e) {
      console.log(e)
    }
  }, [criterion])

  const getServiceCustomer = useCallback(async () => {
    try {
      const response = await isServiceCustomer(criterion)
      setServiceCustomer(response)
    } catch (e) {
      console.log(e)
    }
  }, [criterion])

  const reloadContent = useMemo(
    () => (
      <ReloadText data-testid="reload-section">
        <span
          className="caption"
          data-testid="reload-button-caption"
        >
          {t('ecp-header.lastUpdated', 'Last Updated') + ' ' + lastSynced}
        </span>
        <Button
          appearance="ghost"
          aria-label="ghost"
          data-testid="reload-button"
          leadingIcon={<IconReload />}
          onClick={handleReloadPage}
          small
        ></Button>
      </ReloadText>
    ),
    [handleReloadPage, lastSynced, t]
  )

  useEffect(() => {
    getServiceProvider()
    getServiceCustomer()
    retriveSelectedTenant()
  }, [getServiceCustomer, getServiceProvider, retriveSelectedTenant])

  return (
    <Container data-testid="header-container">
      {serviceProvider && (
        <div
          className={isTenantRoute ? 'orgStyle' : 'tenantStyle'}
          data-testid="header-title"
        >
          <HeaderTitleText data-testid="header-title-text">
            {detailsNavigation()}
            {!emptyTitle &&
              (isTenantRoute
                ? headerTitle
                : headerTitle.length != 1
                  ? tenantName + ' | ' + headerTitle
                  : tenantName)}
          </HeaderTitleText>
          <ReloadText data-testid="header-reload-text">
            {!props.disableReload && reloadContent}
          </ReloadText>
        </div>
      )}
      {serviceCustomer && (
        <div
          className="tenantStyle"
          data-testid="header-title"
        >
          <HeaderTitleText data-testid="header-title-text">
            {detailsNavigation()}
            {!emptyTitle && headerTitle}
          </HeaderTitleText>
          <ReloadText data-testid="header-reload-text">
            {!props.disableReload && reloadContent}
          </ReloadText>
        </div>
      )}
    </Container>
  )
}

export default EcpHeader
