import React from 'react'
import { Container } from './styles'
import * as T from './types'
import EcpHeader from '../components/EcpHeader'

const App = ({ ...props }: T.AppPropsType) => {
  return (
    <Container>
      <EcpHeader {...props} />
    </Container>
  )
}

export default App
